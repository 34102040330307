import { DevOpsGuru } from "aws-sdk";
import React from "react";

const Pagenation = ({
  total,
  currenct,
  page,
  setPage,
}: {
  total: number;
  currenct: number;
  page?: any;
  setPage: any;
}): JSX.Element => {
  const numPages = total;
  return (
    <div className="flex items-center justify-center mt-4">
      {/* <img
        className="cursor-pointer w-6 h-6"
        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-left.svg"
        alt=""
      /> */}
      <div className="flex">
        {Array(numPages)
          .fill(numPages)
          .map((_, i) => (
            <div
              className={`mx-2 cursor-pointer ${
                currenct === i ? "text-light-green" : "text-black"
              }`}
              onClick={() => {
                setPage(i);
              }}
              aria-current={page === i + 1 ? "page" : undefined}
            >
              {i + 1}
            </div>
          ))}
      </div>
      {/* <img
        className="cursor-pointer w-6 h-6"
        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
        alt=""
      /> */}
    </div>
  );
};

export default Pagenation;

import TableDefault from "components/HTMLTagCompo/TableDefault";
import Pagenation from "components/Pagenation";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { HOST_MANAGE } from "components/page/tableData";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChangeDate } from "utils/changeDate";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";

const ManageHost = () => {
  const navigate = useNavigate();
  const [hostData, setHostData] = useState([]);

  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/users/fetch/host`,
      params: { hostName: "", page: currentPage, size: 10, sort: "" },
    })
      .then((res) => {
        const data = res.data.data;
        const totalPages = data.totalPages;
        const size = data.size;
        setTotal(totalPages);
        setLimit(size);
        setHostData(data?.content);
        return data;
      })
      .catch((err) => {});
  }, [currentPage]);
  return (
    <div>
      <PageHeaderTitle title="Manage Host" />
      <PageContainerWrap title="Hosts">
        <TableDefault COLUMNS={HOST_MANAGE}>
          {hostData?.map((item: any, idx) => {
            const id = item.id;
            const name = item.name;
            const bizType = item.bizType;
            const verified = String(item.verified);
            const marketing = String(item.marketingConsent);
            const joined_at = ChangeDate(item.createdAt);
            const last_login = ChangeDate(item.lastLoginAt);

            return (
              <tr key={idx}>
                <td>{id}</td>
                <td>{name}</td>
                <td>{bizType}</td>
                <td>{joined_at}</td>
                <td>
                  <div className="flex min-w-[63px] items-center justify-between">
                    <img
                      onClick={() => {
                        navigate("/manage/user-info", {
                          state: { type: "host", id: id },
                        });
                      }}
                      className="w-5 h-5 cursor-pointer"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/edit-user.svg"
                    />
                    /
                    <img
                      className="w-5 h-5 cursor-pointer"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </TableDefault>
        <Pagenation
          total={total}
          currenct={currentPage}
          setPage={setCurrentPage}
        />
      </PageContainerWrap>
    </div>
  );
};

export default ManageHost;

import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { PRIVATE_COLUMNS } from "components/page/tableData";
import React from "react";
import { ChangeDate } from "utils/changeDate";

const AccommodationListing = () => {
  return (
    <div>
      <PageHeaderTitle title="Listings - Accommodation" />
      <PageContainerWrap title="Listings">
        <TableDefault COLUMNS={PRIVATE_COLUMNS}></TableDefault>
      </PageContainerWrap>
    </div>
  );
};

export default AccommodationListing;
